import { AutofillSuggestion } from '@mapbox/search-js-core';
import { AutofillValueMap } from './autofill';
import { createElementFromString } from './dom';

export type AddressConfirmShowResult =
  | { type: 'change'; suggestion: AutofillSuggestion }
  | { type: 'nochange' }
  | { type: 'cancel' };

/**
 * Creates a formatted, multi-line HTML element representing an address
 * @param autofillValues - A map of address `<input>` values by autocomplete attribute
 * @param baseAddress - A full address string. If provided, this will be used for the
 * first and last lines of the formatted address, and will be supplemented by any `address-line2`
 * and/or `address-line3` values provided through the `autofillValues` map.
 * @returns
 */
export function createAddressElement(
  autofillValues: AutofillValueMap,
  baseAddress?: string
): HTMLElement {
  if (baseAddress) {
    const element = createElementFromString<HTMLSpanElement>(`
        <span>
          <span></span>
          <br />
          <span></span>
        </span>
      `);

    const [firstLine, lastLine] = Array.from(
      element.querySelectorAll<HTMLSpanElement>('span > span')
    );

    const parts = baseAddress.split(',');

    firstLine.textContent = parts[0].trim();
    lastLine.textContent = parts.slice(1).join(',').trim();

    // Add any customer-entered address-line2, address-line3 input values
    if (autofillValues['address-line2']) {
      const span = document.createElement('span');
      span.textContent = autofillValues['address-line2'];
      element.insertBefore(span, lastLine);
      element.insertBefore(document.createElement('br'), lastLine);
    }
    if (autofillValues['address-line3']) {
      const span = document.createElement('span');
      span.textContent = autofillValues['address-line3'];
      element.insertBefore(span, lastLine);
      element.insertBefore(document.createElement('br'), lastLine);
    }

    return element;
  } else {
    const firstLine =
      autofillValues['street-address'] || autofillValues['address-line1'] || '';
    const line2 = autofillValues['address-line2'];
    const line3 = autofillValues['address-line3'];
    const lastLine = [
      autofillValues['address-level4'] || '',
      autofillValues['address-level3'] || '',
      autofillValues['address-level2'] || '',
      `${autofillValues['address-level1'] || ''} ${
        autofillValues['postal-code'] || ''
      }`,
      autofillValues.country || autofillValues['country-name'] || ''
    ]
      .filter(Boolean)
      .join(', ');
    const addressLines = [firstLine, line2, line3, lastLine].filter(Boolean);
    const addressLinesHtml = addressLines
      .map((val) => `<span>${val}</span>`)
      .join('<br />');
    const element = createElementFromString<HTMLSpanElement>(`
        <span>${addressLinesHtml}</span>
      `);
    return element;
  }
}
