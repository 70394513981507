// Type definitions for /suggest endpoint suggestions.

import { LngLatBoundsLike } from '../LngLatBounds';

export enum AutofillMatchCodeConfidence {
  exact = 'exact',
  high = 'high',
  medium = 'medium',
  low = 'low'
}

/**
 * An object describing the level of confidence that the given response feature matches the address intended by the request query.
 * Includes boolean flags denoting matches for each address sub-component.
 */
export interface AutofillMatchCode {
  confidence: AutofillMatchCodeConfidence;
  exact_match: boolean;
  house_number: boolean;
  street: boolean;
  postcode: boolean;
  place: boolean;
  region?: boolean;
  locality?: boolean;
}

/**
 * An `AutofillSuggestion` object represents a suggestion
 * result from the Mapbox Autofill API.
 *
 * Suggestion objects are "part one" of the two-step interactive autofill experience.
 * Suggestion objects do not include geographic coordinates.
 *
 * To get the coordinates of the result, use {@link MapboxAutofill#retrieve}.
 *
 * For tracking purposes, it is useful for any follow-up requests based on this suggestion to include same
 * {@link SessionToken} as the original request.
 *
 * @typedef AutofillSuggestion
 * @example
 * ```typescript
 * const autofill = new MapboxAutofill({ accessToken: 'pk.my-mapbox-access-token' });
 *
 * const sessionToken = new SessionToken();
 * const result = await search.autofill('Washington D.C.', { sessionToken });
 * if (result.suggestions.length === 0) return;
 *
 * const suggestion = result.suggestions[0];
 * const { features } = await autofill.retrieve(suggestion, { sessionToken });
 * doSomethingWithCoordinates(features);
 * ```
 */
export interface AutofillSuggestion {
  /**
   * This is added by {@link MapboxAutofill} and is **not** part of the
   * Autofill API.
   *
   * @ignore
   */
  original_search_text: string;

  /**
   * The name of the feature.
   */
  feature_name: string;
  /**
   * The feature name, as matched by the search algorithm.
   */
  matching_name: string;
  /**
   * Additional details, such as city and state for addresses.
   */
  description: string;
  /**
   * The name of the [Maki](https://labs.mapbox.com/maki-icons/) icon associated with the feature.
   */
  maki?: string;
  /**
   * The [IETF language tag](https://en.wikipedia.org/wiki/IETF_language_tag) of the feature.
   */
  language: string;

  address?: string;
  /**
   * The full address of the suggestion.
   */
  full_address?: string;

  /**
   * Address line 1 from the [WHATWG Autocomplete Specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill)
   */
  address_line1?: string;
  /**
   * Address line 2 from the [WHATWG Autocomplete Specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill)
   */
  address_line2?: string;
  /**
   * Address line 3 from the [WHATWG Autocomplete Specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill)
   */
  address_line3?: string;

  /**
   * Address level 1 from the [WHATWG Autocomplete Specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill)
   */
  address_level1?: string;
  /**
   * Address level 2 from the [WHATWG Autocomplete Specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill)
   */
  address_level2?: string;
  /**
   * Address level 3 from the [WHATWG Autocomplete Specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill)
   */
  address_level3?: string;

  /**
   * Long form country name, for example: "United States"
   */
  country?: string;
  /**
   * Postal code.
   */
  postcode?: string;

  /**
   * Address metadata fields of the feature.
   *
   * Includes the short form country name, for example: "us". This follows the
   * [ISO 3166 alpha-2 country code](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) specification.
   */
  metadata: {
    iso_3166_1: string;
  };

  /**
   * A string representing the feature in the requested language, if specified, and its full result hierarchy.
   */
  place_name?: string;

  /**
   * An object describing the level of confidence that the given response feature matches the address intended by the request query.
   * Includes boolean flags denoting matches for each address sub-component.
   */
  match_code: AutofillMatchCode;
}

/**
 * An `AutofillFeatureSuggestion` object represents [GeoJSON](https://docs.mapbox.com/help/glossary/geojson/)
 * suggestion results from the Mapbox Autofill API.
 *
 * As per the Mapbox Autofill API, this will always be [Point](https://geojson.org/geojson-spec.html#point).
 *
 * @typedef AutofillFeatureSuggestion
 * @example
 * ```typescript
 * const featureSuggestion = {
 *   type: 'Feature',
 *   geometry: {
 *     type: 'Point',
 *     coordinates: [0,0]
 *   },
 *   properties: {
 *     feature_name: 'Washington D.C.',
 *   }
 * };
 * ```
 */
export type AutofillFeatureSuggestion = GeoJSON.Feature<
  GeoJSON.Point,
  AutofillSuggestion
> & {
  /**
   * A bounding box for the feature. This may be significantly
   * larger than the geometry.
   */
  bbox?: LngLatBoundsLike;
};
