import { createPopper } from '@popperjs/core/lib/popper-lite';
import {
  SearchSession,
  Suggestion as SearchSuggestion,
  AutofillSuggestion
} from '@mapbox/search-js-core';

import { HTMLScopedElement } from './HTMLScopedElement';

import { MapboxHTMLEvent } from '../MapboxHTMLEvent';
import { Theme, getThemeCSS } from '../theme';
import { randomValidID } from '../utils';
import {
  bindElements,
  createElementFromString,
  getChildElements
} from '../utils/dom';

import style from '../style.css';

const TEMPLATE = createElementFromString<HTMLTemplateElement>(`
<template>
  <div class="MapboxSearch">
      <div class="Label" role="label" aria-live="polite" aria-atomic="true">
      </div>
      <div class="Results" aria-hidden="true">
        <div class="ResultsList" role="listbox">
        </div>
        <div class="ResultsAttribution" aria-hidden="true">
          <a href="https://www.mapbox.com/search-service" target="_blank" tabindex="-1">
            Powered by Mapbox
          </a>
        </div>
      </div>
  </div>
</template>
`);

const RENDER_TEMPLATE = createElementFromString<HTMLTemplateElement>(`
<template>
  <div class="Suggestion" role="option">
    <div class="SuggestionName"></div>
    <div class="SuggestionDesc"></div>
  </div>
</template>
`);

function getAriaIdForSuggestion(resultListId: string, i: number): string {
  return `${resultListId}-${i}`;
}

type Binding = {
  /**
   * Wrapper around the entire component.
   */
  MapboxSearch: HTMLElement;
  /**
   * Results container, which contains:
   * - {@link ResultsList}
   * - {@link Label}
   * - Attribution.
   */
  Results: HTMLDivElement;
  /**
   * Exposed as a listbox to assistive technologies.
   */
  ResultsList: HTMLUListElement;
  /**
   * Exposed as a label.
   *
   * Visually hidden but can "announce" the current selection to
   * assistive technologies.
   */
  Label: HTMLDivElement;
};

type ListboxEventTypes<SuggestionResponse, RetrieveResponse> = {
  suggest: MapboxHTMLEvent<SuggestionResponse>;
  suggesterror: MapboxHTMLEvent<Error>;
  retrieve: MapboxHTMLEvent<RetrieveResponse>;
};

export class MapboxSearchListbox<
  Options,
  Suggestion extends SearchSuggestion | AutofillSuggestion,
  SuggestionResponse extends { suggestions: Suggestion[] },
  RetrieveResponse
> extends HTMLScopedElement<
  ListboxEventTypes<SuggestionResponse, RetrieveResponse>
> {
  protected override get template(): HTMLTemplateElement {
    return TEMPLATE;
  }

  protected override get templateStyle(): string {
    return style;
  }

  protected get templateUserStyle(): string {
    return getThemeCSS('.MapboxSearch', this.theme);
  }

  #sessionInternal: SearchSession<
    Options,
    Suggestion,
    SuggestionResponse,
    RetrieveResponse
  > | null;

  get session(): SearchSession<
    Options,
    Suggestion,
    SuggestionResponse,
    RetrieveResponse
  > | null {
    return this.#sessionInternal;
  }

  set session(
    newSession: SearchSession<
      Options,
      Suggestion,
      SuggestionResponse,
      RetrieveResponse
    > | null
  ) {
    const oldSession = this.#sessionInternal;

    if (oldSession) {
      newSession.removeEventListener('suggest', this.#handleSuggest);
      newSession.removeEventListener('suggesterror', this.#handleSuggestError);
    }

    if (newSession) {
      newSession.addEventListener('suggest', this.#handleSuggest);
      newSession.addEventListener('suggesterror', this.#handleSuggestError);
    }

    this.#sessionInternal = newSession;
  }

  get suggestions(): Suggestion[] | null {
    return this.session.suggestions?.suggestions;
  }

  #popper: ReturnType<typeof createPopper> | null = null;

  #binding: Binding;

  #labelID = randomValidID();
  #resultListID = randomValidID();

  #inputInternal: HTMLInputElement | null;

  get input(): HTMLInputElement | null {
    return this.#inputInternal;
  }

  set input(newInput: HTMLInputElement | null) {
    const oldInput = this.#inputInternal;

    if (oldInput) {
      oldInput.removeEventListener('input', this.#handleInput);
      oldInput.removeEventListener('focus', this.#handleFocus);
      oldInput.removeEventListener('blur', this.#handleBlur);
      oldInput.removeEventListener('keydown', this.#handleKeyDown);

      if (this.#popper) {
        this.#popper.destroy();
      }
    }

    if (newInput) {
      newInput.addEventListener('input', this.#handleInput);
      newInput.addEventListener('focus', this.#handleFocus);
      newInput.addEventListener('blur', this.#handleBlur);
      newInput.addEventListener('keydown', this.#handleKeyDown);

      // Set ARIA role and attributes.
      newInput.setAttribute('role', 'combobox');
      newInput.setAttribute('aria-autocomplete', 'list');
      newInput.setAttribute('aria-controls', this.#resultListID);

      if (this.isConnected) {
        this.#popper = createPopper(newInput, this.#binding.Results, {
          placement: 'bottom-start'
        });
      }
    }

    this.#inputInternal = newInput;
  }

  #selectedIndexInternal = 0;

  get selectedIndex(): number {
    return this.#selectedIndexInternal;
  }

  set selectedIndex(newIndex: number) {
    const oldIndex = this.#selectedIndexInternal;
    this.#selectedIndexInternal = newIndex;

    // Update accessibility flags.
    const { ResultsList, Label } = this.#binding;

    const id = getAriaIdForSuggestion(this.#resultListID, newIndex);
    this.input.setAttribute('aria-activedescendant', id);
    ResultsList.setAttribute('aria-activedescendant', id);

    // Update the selected suggestion.
    if (oldIndex !== newIndex) {
      const oldId = getAriaIdForSuggestion(this.#resultListID, oldIndex);
      const oldEl = ResultsList.querySelector(`#${oldId}`);
      oldEl?.removeAttribute('aria-selected');

      const el = ResultsList.querySelector(`#${id}`);
      el?.setAttribute('aria-selected', 'true');
    }

    Label.textContent =
      this.suggestions[newIndex].address +
      `: Suggestion ${newIndex + 1} of ${this.suggestions.length}`;
  }

  #showResults(): void {
    if (!this.suggestions || !this.suggestions.length) {
      return;
    }

    const { Results, MapboxSearch } = this.#binding;

    // Calculate position.
    const rect = this.input.getBoundingClientRect();
    MapboxSearch.style.setProperty('--width', `${rect.width}px`);

    // Update accessibility flags.
    this.input.setAttribute('aria-expanded', 'true');
    Results.removeAttribute('aria-hidden');
    // Reset selected index.
    this.selectedIndex = 0;
  }

  #hideResults(): void {
    const { Results, ResultsList } = this.#binding;

    // Update accessibility flags.
    Results.setAttribute('aria-hidden', 'true');
    this.input.removeAttribute('aria-expanded');
    ResultsList.removeAttribute('aria-activedescendant');
    this.input.removeAttribute('aria-activedescendant');
  }

  renderItem(i: number): HTMLElement {
    const element = this.prepareTemplate(RENDER_TEMPLATE);
    element.id = getAriaIdForSuggestion(this.#resultListID, i);

    return element;
  }

  fillItem(el: Element, item: Suggestion, i: number): void {
    const [nameEl, descriptionEl] = Array.from(
      el.querySelectorAll('[role="option"] > *')
    );

    nameEl.textContent = item.feature_name;
    descriptionEl.textContent = item.description;

    if (i === this.selectedIndex) {
      el.setAttribute('aria-selected', 'true');
    } else {
      el.removeAttribute('aria-selected');
    }
  }

  #renderResultsList(): void {
    const { ResultsList } = this.#binding;
    const suggestions = this.suggestions;

    if (!suggestions || !suggestions.length) {
      // Speed optimization?
      ResultsList.innerHTML = '';
      this.#hideResults();
      return;
    }

    /**
     * Make sure we have the correct number of nodes.
     */
    const elements = getChildElements(ResultsList);
    // Too few, add any we're missing.
    if (suggestions.length > elements.length) {
      for (let i = elements.length; i < suggestions.length; i++) {
        const item = this.renderItem(i);
        elements.push(item);

        // Setup selected index listener.
        item.onmouseenter = () => {
          this.selectedIndex = i;
        };

        ResultsList.appendChild(item);
      }
    }

    // Too many, remove any we're not using anymore.
    if (suggestions.length < elements.length) {
      for (let i = suggestions.length; i < elements.length; i++) {
        elements[i].remove();
      }
    }

    /**
     * Fill out DOM nodes with our data.
     */
    for (const suggestion of suggestions) {
      const i = suggestions.indexOf(suggestion);
      const element = elements[i];

      this.fillItem(element, suggestion, i);
      // Override 'onclick' for autofill.
      element.onclick = () => {
        this.retrieve(suggestion);
      };
    }
  }

  #optionsInternal: Partial<Options> = {};

  get options(): Partial<Options> {
    return this.#optionsInternal;
  }

  set options(newOptions: Partial<Options>) {
    this.#optionsInternal = newOptions;
  }

  #themeInternal: Theme = {};

  get theme(): Theme {
    return this.#themeInternal;
  }

  set theme(theme: Theme) {
    this.#themeInternal = theme;

    if (!this.#binding || !theme) {
      return;
    }

    this.updateTemplateUserStyle(getThemeCSS('.MapboxSearch', theme));
  }

  #handleInput = (e: InputEvent): void => {
    // Prevent duping requests.
    const { Results } = this.#binding;
    const input = e.target as HTMLInputElement;

    if (input.dataset['mapboxSuccess']) {
      delete input.dataset['mapboxSuccess'];
      return;
    }

    const searchText = input.value;

    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-busy
    Results.setAttribute('aria-busy', 'true');

    this.session.suggest(searchText, this.options);
  };

  /**
   * Connected to {@link SearchSession}.
   */
  #handleSuggest = (result: SuggestionResponse): void => {
    if (!result || !result.suggestions) {
      this.#hideResults();
      return;
    }

    this.#renderResultsList();
    if (result.suggestions.length) {
      this.#showResults();
    }

    // Make sure to fire events after our side-effects are done.
    this.dispatchEvent(new MapboxHTMLEvent('suggest', result));

    const { Results } = this.#binding;
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-busy
    Results.setAttribute('aria-busy', 'false');
  };

  /**
   * Connected to {@link SearchSession}.
   */
  #handleSuggestError = (error: Error): void => {
    // TODO: Add a user facing event view.
    this.dispatchEvent(new MapboxHTMLEvent('suggesterror', error));

    const { Results } = this.#binding;
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-busy
    Results.setAttribute('aria-busy', 'false');

    this.#hideResults();
  };

  #handleFocus = (): void => {
    const input = this.input;
    delete input.dataset['mapboxSuccess'];

    this.#showResults();
  };

  #handleBlur = (): void => {
    // See if we're the target.
    if (document.activeElement === this.input) {
      return;
    }

    // Abort any in-progress operations.
    this.session.abort();
    this.#hideResults();
  };

  #handleKeyDown = (e: KeyboardEvent): void => {
    if (e.key === 'Escape') {
      this.#hideResults();
      return;
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      this.selectedIndex = Math.max(0, this.selectedIndex - 1);
      return;
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      this.selectedIndex = Math.min(
        this.selectedIndex + 1,
        this.suggestions.length - 1
      );
      return;
    }

    if (e.key === 'Enter') {
      e.preventDefault();
      this.retrieve(this.suggestions[this.selectedIndex]);
      return;
    }
  };

  override connectedCallback(): void {
    super.connectedCallback();

    this.#binding = bindElements<Binding>(this, {
      MapboxSearch: '.MapboxSearch',
      Results: '.Results',
      ResultsList: '.ResultsList',
      Label: '.Label'
    });

    const { Results, ResultsList, Label } = this.#binding;

    Label.id = this.#labelID;
    ResultsList.id = this.#resultListID;
    ResultsList.setAttribute('aria-labelledby', this.#labelID);

    Results.addEventListener('blur', this.#handleBlur);

    if (!this.#popper && this.input) {
      this.#popper = createPopper(this.input, this.#binding.Results, {
        placement: 'bottom-start'
      });
    }

    // Update popper on next frame.
    requestAnimationFrame(() => {
      if (this.#popper) {
        this.#popper.update();
      }
    });
  }

  disconnectedCallback(): void {
    // Make sure to unbind input listeners.
    this.input = null;

    const { Results } = this.#binding;
    Results.removeEventListener('blur', this.#handleBlur);
  }

  async retrieve(suggestion: Suggestion): Promise<void> {
    const input = this.input;
    if (input) {
      input.dataset['mapboxSuccess'] = 'true';
    }

    const result = await this.session.retrieve(suggestion, this.options);

    this.#hideResults();
    this.dispatchEvent(new MapboxHTMLEvent('retrieve', result));
  }

  focus(): void {
    // Refire the event internally, in case we missed it
    // and the end user is trying to replay it.
    if (document.activeElement === this.input) {
      this.#handleFocus();
    } else {
      this.input.focus();
    }
  }

  updatePopover(): void {
    if (this.#popper) {
      this.#popper.update();
    }
  }
}

declare global {
  interface Window {
    MapboxSearchListbox: typeof MapboxSearchListbox;
  }
}

window.MapboxSearchListbox = MapboxSearchListbox;

if (!window.customElements.get('mapbox-search-listbox')) {
  customElements.define('mapbox-search-listbox', MapboxSearchListbox);
}
