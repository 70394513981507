import { SessionToken } from '@mapbox/search-js-core';

class Config {
  /**
   * A Mapbox access token used if one is not passed in explicitly to web components
   */
  accessToken: string;
  /**
   * If true, Autofill address or marker location corrections will be submitted to the Mapbox Contribute API to help improve our data accuracy. Defaults to true.
   */
  feedbackEnabled = true;

  readonly autofillSessionToken = new SessionToken(); // Shared session token used among Autofill-related web components
  autofillSessionEnabled = false; // True when Autofill has been initialized within the current page view
}

const config = new Config();

// Prevent autofillSessionToken from being overwritten
Object.defineProperty(config, 'autofillSessionToken', {
  configurable: false,
  writable: false
});

export { config };
