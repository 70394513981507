import {
  AutofillOptions,
  AutofillRetrieveResponse,
  AutofillSuggestionResponse,
  Evented,
  MapboxAutofill,
  SearchSession
} from '@mapbox/search-js-core';

import { MapboxSearchListboxAutofillType } from './components/MapboxAddressAutofill';
import { MapboxSearchListbox } from './components/MapboxSearchListbox';
import { MapboxHTMLEvent } from './MapboxHTMLEvent';
import { Theme } from './theme';
import { deepEquals } from './utils';
import { fillFormWithFeature, findAddressInputs } from './utils/autofill';

import { config } from './config';

/**
 * @class AutofillInstance
 */
export class AutofillInstance {
  #input: HTMLInputElement;
  #collection: AutofillCollectionType;

  listbox: MapboxSearchListboxAutofillType = new MapboxSearchListbox();

  constructor(
    collection: AutofillCollectionType,
    input: HTMLInputElement,
    autofillRef: MapboxAutofill
  ) {
    this.#input = input;
    this.#collection = collection;
    this.listbox.input = this.#input;

    // Bind the listbox to the session.
    this.listbox.session = new SearchSession(autofillRef);
    this.listbox.session.sessionToken = config.autofillSessionToken;

    this.listbox.addEventListener('suggest', this.#handleSuggest);
    this.listbox.addEventListener('suggesterror', this.#handleSuggestError);
    this.listbox.addEventListener('retrieve', this.#handleRetrieve);

    document.body.appendChild(this.listbox);
  }

  remove(): void {
    this.listbox.remove();
    this.listbox.removeEventListener('suggest', this.#handleSuggest);
    this.listbox.removeEventListener('suggesterror', this.#handleSuggestError);
    this.listbox.removeEventListener('retrieve', this.#handleRetrieve);
  }

  #handleSuggest = (e: MapboxHTMLEvent<AutofillSuggestionResponse>): void => {
    // Manually bubble up the event.
    this.#collection.fire('suggest', e.clone(this.#input));
  };

  #handleSuggestError = (e: MapboxHTMLEvent<Error>): void => {
    // Manually bubble up the event.
    this.#collection.fire('suggesterror', e.clone(this.#input));
  };

  #handleRetrieve = (e: MapboxHTMLEvent<AutofillRetrieveResponse>): void => {
    // Manually bubble up the event.
    this.#collection.fire('retrieve', e.clone(this.#input));

    if (!this.#input) {
      return;
    }

    const featureCollection = e.detail;
    if (
      !featureCollection ||
      !featureCollection.features ||
      !featureCollection.features.length
    ) {
      return;
    }

    fillFormWithFeature(featureCollection.features[0], this.#input);
  };
}

interface AutofillCollectionOptions {
  accessToken?: string;
  options?: Partial<AutofillOptions>;
  theme?: Theme;
}

interface EventTypes<AutofillSuggestionResponse, AutofillRetrieveResponse> {
  suggest: MapboxHTMLEvent<AutofillSuggestionResponse>;
  suggesterror: MapboxHTMLEvent<Error>;
  retrieve: MapboxHTMLEvent<AutofillRetrieveResponse>;
}

type AutofillCollectionType = AutofillCollection<
  AutofillSuggestionResponse,
  AutofillRetrieveResponse
>;

/**
 * @class AutofillCollection
 */
export class AutofillCollection<
  AutofillSuggestionResponse,
  AutofillRetrieveResponse
> extends Evented<
  EventTypes<AutofillSuggestionResponse, AutofillRetrieveResponse>
> {
  instances: AutofillInstance[] = [];
  #currentInputs: HTMLInputElement[];

  #autofill = new MapboxAutofill();

  constructor({ accessToken, options, theme }: AutofillCollectionOptions) {
    super();

    config.autofillSessionEnabled = true;

    this.accessToken = accessToken || config.accessToken;
    options && (this.options = options);
    theme && (this.theme = theme);
    this.update();
  }

  /**
   * The [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) to use for all requests.
   *
   * @example
   * ```typescript
   * autofill.accessToken = 'pk.my-mapbox-access-token';
   * ```
   */
  get accessToken(): string {
    return this.#autofill.accessToken;
  }
  set accessToken(newToken: string) {
    this.#autofill.accessToken = newToken;
  }

  #options: Partial<AutofillOptions>;

  /**
   * Options to pass to the underlying {@link MapboxAutofill} interface.
   *
   * @example
   * ```typescript
   * autofill.options = {
   *  language: 'en',
   *  country: 'US',
   * };
   * ```
   */
  get options(): Partial<AutofillOptions> {
    return this.#options;
  }
  set options(newOptions: Partial<AutofillOptions>) {
    this.#options = { ...this.#options, ...newOptions };
    this.instances.forEach((instance) => {
      instance.listbox.options = { ...instance.listbox.options, ...newOptions };
    });
  }

  #theme: Theme;

  /**
   * The {@link Theme} to use for styling the autofill component.
   *
   * @example
   * ```typescript
   * autofill.theme = {
   *   variables: {
   *     colorPrimary: 'myBrandRed'
   *   }
   * };
   * ```
   */
  get theme(): Theme {
    return this.#theme;
  }
  set theme(newTheme: Theme) {
    this.#theme = newTheme;
    this.instances.forEach((instance) => {
      instance.listbox.theme = newTheme;
    });
  }

  /** @section {Methods} */

  /**
   * Updates autofill collection based on the current DOM state.
   * @example
   * ```typescript
   * collection.update();
   * ```
   */
  update(): void {
    // STEP 0: Remove and clean up any existing autofill instances
    this.instances.forEach((instance) => {
      instance.remove();
    });
    // STEP 1: Find the input element(s)
    this.#currentInputs = findAddressInputs();
    // STEP 2: Create a new autofill instance for each input
    this.instances = [];
    this.#currentInputs.forEach((input) => {
      const autofillInstance = new AutofillInstance(
        this,
        input,
        this.#autofill
      );
      autofillInstance.listbox.options = this.options;
      autofillInstance.listbox.theme = this.theme;
      this.instances.push(autofillInstance);
    });
  }

  // TODO: optimize this!
  // Called when content changes.
  #handleObserve = (): void => {
    // TODO: add test to make sure this comparison works
    if (!deepEquals(findAddressInputs(), this.#currentInputs)) {
      this.update();
    }
  };

  #observer = new MutationObserver(this.#handleObserve);

  /**
   * Listen for changes to the DOM, and update autofill instances when autofill-able inputs are added/removed.
   *
   * **IMPORTANT:** For performance reasons, it is recommended to carefully control
   * when this is called and to call {@link AutofillCollection#unobserve} when finished.
   */
  observe(): void {
    // Setup observer handler.
    this.#observer.observe(document, {
      subtree: true,
      childList: true
    });

    this.#handleObserve();
  }

  /**
   * Stop listening for changes to the DOM. This only has an effect if called
   * after {@link AutofillCollection#observe}.
   */
  unobserve(): void {
    this.#observer.disconnect();
  }
}

/**
 * Entry point for Mapbox Address Autofill, for use on standard HTML input elements.
 *
 * Compared to {@link MapboxAddressAutofill}, this function automatically attaches
 * to eligible inputs in place.
 *
 * You must have a [Mapbox access token](https://www.mapbox.com/help/create-api-access-token/).
 *
 * Eligible inputs must be a descendant of a [`<form>`](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form) element, and the form
 * must have inputs with proper HTML `autocomplete` attributes. The input itself must be of autocomplete `"street-address"` or `"address-line1""`.
 *
 * If your application works with browser autofill, you may already have this functionality.
 * - [The HTML autocomplete attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete)
 * - [Autofill](https://web.dev/learn/forms/autofill/)
 *
 * @param optionsArg
 * @param {string} optionsArg.accessToken
 * @param {Partial<AutofillOptions>} [optionsArg.options]
 * @example
 * <input type="text" autocomplete="street-address" />
 * <script>
 * autofill({
 *   accessToken: 'pk.my.token',
 *   options: {}
 * };
 * </script>
 * @example
 * ```typescript
 * const collection = mapboxsearch.autofill({
 *   accessToken: 'pk.my.token',
 *   options
 * })
 *
 * myClientSideRouter.on('route', () => collection.update());
 * ```
 */
export function autofill(
  optionsArg: AutofillCollectionOptions
): AutofillCollectionType {
  const { accessToken, options } = optionsArg;
  return new AutofillCollection({
    accessToken,
    options
  });
}
